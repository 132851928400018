import React from "react"

export const BalukoCardMv = () => {
  return (
    <section className="balukoCardMv">
        <img
          className="balukoCardMv__mainVisual balukoCardMv__mainVisualPc"
          src="https://balukoweb.blob.core.windows.net/images/balukocard/balukocard01.jpg"
          height="auto"
          loading="eager"
          alt="mainVisual"
        />
        <img
          className="balukoCardMv__mainVisual balukoCardMv__mainVisualSp"
          src="https://balukoweb.blob.core.windows.net/images/balukocard/balukocard01-sp.png"
          height="auto"
          loading="eager"
          alt="mainVisual"
        />
    </section>
  )
}
