import React from "react"
import { Link } from "gatsby"

export const BalukoCardUsage = () => {
  return (
    <section className="balukoCardUsage">
      <div className="balukoCardUsage__item">
        <h4 className="balukoCardUsage__title e">How to use</h4>
        <p className="balukoCardUsage__smallTitle jp fw-b">使い方</p>

        <div className="balukoCardUsage__explanation">
          <h4 className="balukoCardUsage__explanationTitle">
            貯まったポイントを利用するには
          </h4>

          <div className="balukoCardUsage__leadWrap">
            <p className="balukoCardUsage__lead fw-b">
              店内設置のマルチ端末にて「ポイント換金」を選択し、
            </p>
            <p className="balukoCardUsage__lead fw-b">
              チャージ残高に移行してご利用ください
            </p>
            <p className="balukoCardUsage__lead fw-b">
              100ポイント＝100円で、
              <br className="sp" />
              100ポイント単位で換金できます。
            </p>
          </div>
        </div>
        <div className="balukoCardUsage__explanation">
          <h4 className="balukoCardUsage__explanationTitle">
            バルコカードで支払うには
          </h4>

          <div className="balukoCardUsage__leadWrap">
            <p className="balukoCardUsage__lead fw-b">
              ご利用の機器に洗濯物を入れ、コースを選択したあと、
            </p>
            <p className="balukoCardUsage__lead fw-b">
              ランドリー機器のカード挿入口にカードを
              <br className="sp" />
              挿し込んでください。
            </p>
            <p className="balukoCardUsage__lead fw-b">
              「支払い」ボタンを押すと、カードの残高から必要な料金
              <br className="sp" />
              が差し引かれ、機器が稼働を開始します。
            </p>
          </div>
        </div>

        <div className="balukoCardUsage__example">
          <div className="balukoCardUsage__exampleItem balukoCardUsage__exampleItem--left">
            <img
              className="balukoCardUsage__exampleLeftItemImg"
              src="https://balukoweb.blob.core.windows.net/images/balukocard/balukocard03.png"
              height="auto"
              loading="eager"
              alt="usageExample"
            />

            <div className="balukoCardUsage__exampleItemTextWrap balukoCardUsage__exampleItemTextWrap--left">
              <p className="balukoCardUsage__exampleItemText fw-b">
                1.機器に洗濯物を入れ、コースを選択する
              </p>
              <p className="balukoCardUsage__exampleItemText fw-b">
                2.カードを差し込む
              </p>
            </div>
          </div>
          <div className="balukoCardUsage__verticalLine"></div>
          <div className="balukoCardUsage__exampleItem balukoCardUsage__exampleItem--right">
            <img
              className="balukoCardUsage__exampleRightItemImg"
              src="https://balukoweb.blob.core.windows.net/images/balukocard/balukocard04.png"
              height="auto"
              loading="eager"
              alt="usageExample"
            />
            <div className="balukoCardUsage__exampleItemTextWrap balukoCardUsage__exampleItemTextWrap--right">
              <p className="balukoCardUsage__exampleItemText balukoCardUsage__exampleItemText--right fw-b">
                3.「支払い」ボタンを押す
              </p>
              <p className="balukoCardUsage__exampleItemNote">
                ＊2段式乾燥機は上下に専用の「支払い」ボタンが
                <br className="pc" />
                あります。ご注意ください。
              </p>
            </div>
          </div>
        </div>

        <ul className="balukoCardUsage__checkedList fw-b tx-center">
          <li className="balukoCardUsage__checkListItem">
            <img
              src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
              alt="checked"
            />
            <p>
              一部の洗濯機は「支払い」ボタンを押したあと、「スタート」ボタンを押す必要があります。
            </p>
          </li>
          <li className="balukoCardUsage__checkListItem">
            <img
              src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
              alt="checked"
            />
            <p>
              乾燥機の場合は1回「支払い」ボタンを押すごとに100円支払われます。
            </p>
          </li>
          <li className="balukoCardUsage__checkListItem">
            <img
              src="https://balukoweb.blob.core.windows.net/images/self-laundry/icon/check.svg"
              alt="checked"
            />
            <p>
              残高が足りない場合、現金と併用ができます。後から不足分の硬貨を投入してください。
            </p>
          </li>
        </ul>
      </div>

      <div className="balukoCardUsage__item">
        <h4 className="balukoCardUsage__title e">Notes</h4>
        <p className="balukoCardUsage__smallTitle jp fw-b">ご注意事項</p>

        <div className="balukoCardUsage__noteWrap">
          <p className="balukoCardUsage__note fw-b">
            発行店舗以外ではご利用いただけません。
          </p>
          <p className="balukoCardUsage__note fw-b">
            ポイントの有効期限は最終ご利用日（新規発行・​チャージ・機器稼働）から3年です。
          </p>
          <p className="balukoCardUsage__note fw-b">
            チャージ残高、ポイント残高の返金はできません。​
          </p>
          <p className="balukoCardUsage__smallNote">
            ご利用規約は
            <Link
              className="balukoCardUsage__noteLink"
              to="/terms-of-cardservice"
            >
              こちら
            </Link>
            からご確認ください。
          </p>
          <p className="balukoCardUsage__textCetnter">
            <Link className="balukoCardUsage__noteLink"
              to="/self-laundry/balukocard/protect">
            利用者の保護について
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}
