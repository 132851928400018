import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import { Fv } from "components/fv"
import { BalukoCardAbout } from "components/self-laundry/balukocard/balukoCardAbout"
import { BalukoCardMv } from "components/self-laundry/balukocard/balukoCardMv"
import { BalukoCardUsage } from "components/self-laundry/balukocard/balukoCardUsage"

const BalukoCard = () => {
  return (
    <Layout>
      <div className="balukoCard">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/balukocard/card-page-mv.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/balukocard/card-page-mv-sp.jpg"
          fvTitle="Baluko Card"
          color="white"
        />
        <BalukoCardAbout />
        <BalukoCardMv />
        <BalukoCardUsage />
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Baluko Card"
    description=""
  />
)
export default BalukoCard


