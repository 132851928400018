import React from "react"

export const BalukoCardAbout = () => {
  return (
    <section className="balukoCardAbout">
      <h2 className="balukoCardAbout__title">バルコカード</h2>
      <div className="balukoCardAbout__leadWrap">
        <p className="balukoCardAbout__lead fw-b">
          一部の店舗でご利用いただけるチャージ式ICカードです。
        </p>
        <p className="balukoCardAbout__lead fw-b">
          ランドリー機器利用時のお支払いにご利用でき、チャージするたびに金額の5%のポイントが貯まります。
        </p>
        <p className="balukoCardAbout__note">
          ※店舗によって付与率の異なる店舗がございます。詳しくは店内設置のリーフレットをご確認ください。
        </p>
        <p className="balukoCardAbout__lead fw-b">
          店内設置のマルチ端末より新規発行・チャージができ、くり返しご利用いただけます。
        </p>
      </div>

      <div className="balukoCardAbout__deals">
        <img
          className="balukoCardAbout__dealsImg"
          src="https://balukoweb.blob.core.windows.net/images/balukocard/balukocard02.png"
          loading="eager"
          alt="チャージするたびに金額の5%ポイントプレゼント！"
        />
      </div>
    </section>
  )
}
